<template>
    <div id="authors" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="authors_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Autor
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12" v-if="!loading && !fetch_error && !empty_search">
            <ns-table :data="authors_data" 
                :allow_headers="authors_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:active="data">
                    <span>
                        {{ data.item.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 100px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">
                                <button @click.stop="selectTableItem('edit', data.item.id_author, data.item)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id_author)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id_author)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>
                        </div>

                        
                    </div>
                </template>
            </ns-table>
        </div>

        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="authors_modal" bg_color="white" max_width="680"
            overlay_close @onClose="authors_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">


                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Nombres:" 
                        theme="light"
                        v-model="first_name"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.first_name">
                        {{ errors.first_name }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Apellido Paterno:" 
                        theme="light"
                        v-model="last_name"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.last_name">
                        {{ errors.last_name }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Apellido Materno:" 
                        theme="light"
                        v-model="second_surname"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.second_surname">
                        {{ errors.second_surname }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Especialidad:" 
                        theme="light"
                        v-model="speciality"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.speciality">
                        {{ errors.speciality }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Imagen del Autor.
                    </label>
                    <ns-dropfile 
                        _id="image_1"
                        @charged="checkFile"
                        style="height: 255px;"
                        variant="success">
                    </ns-dropfile>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Preview
                    </label>
                    <div style="max-width:300px;">
                        <img class="img-fluid"
                        :src="preview_image" />
                    </div>
                </div>

                <div class="col-12 mt-3 montserrat-bold">
                    <div class="row justify-content-end align-items-center h-100">
                        <div class="col-6 d-flex justify-content-between">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                authors_modal: false,
                delete_modal: false,
                active_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                authors_headers: [
                    // {key: 'title', label: 'Título'},
                    // {key: 'date', label: 'Fecha'},
                    {key: 'first_name', label: 'Nombres'},
                    {key: 'last_name', label: 'A. Paterno'},
                    {key: 'second_surname', label: 'A. Materno'},
                    {key: 'specialty', label: 'Especialidad'},
                    {key: 'active', label: 'Estatus'},
                    {key: 'actions', label: 'Acciones'}
                ],
                authors_data: [],
                first_name: null,
                last_name: null,
                thumbnail: null,
                preview_image: null,
                second_surname: null,
                speciality: null,
                selected_id: null,
                errors: {
                    first_name: null,
                    last_name: null,
                    second_surname: null,
                    speciality: null
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        methods: {
            //helpers.
            checkFile(file){
                this.preview_image = file.url;
                this.thumbnail = file.url;  
            },
            clearFields() {
             
                this.first_name = null
                this.last_name = null
                this.second_surname = null
                this.speciality = null
                this.selected_id = null
                this.preview_image = null
                this.thumbnail = null

                this.authors_modal = false;
                this.active_modal = false;
                this.delete_modal = false;

                for(let error in this.errors) {
                    this.errors[error] = null
                }
            },
            async selectTableItem(type, id, data = null){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'edit':
                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        this.selected_id = id
                        await this.$store.dispatch('authors/view', { id_author: this.selected_id})
                        this.first_name = this.author.first_name
                        this.last_name = this.author.last_name
                        this.second_surname = this.author.second_surname
                        this.speciality = this.author.specialty
                        this.preview_image = this.author.picture
                        this.thumbnail = null
                        this.authors_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('authors/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('authors/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('authors/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.authors === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.authors_data = this.authors.map( author => {
                    return {
                        ...author,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            // aliment CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.first_name) || _.isEmpty(this.first_name)) {
                    this.errors.first_name = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.last_name) || _.isEmpty(this.last_name)) {
                    this.errors.last_name = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.second_surname) || _.isEmpty(this.second_surname)) {
                    this.errors.second_surname = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.speciality) || _.isEmpty(this.speciality)) {
                    this.errors.speciality = '*Campo requerido'
                    complete = false
                }


                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                first_name: this.first_name,
                                last_name: this.last_name,
                                second_surname: this.second_surname,
                                specialty: this.speciality,
                                picture: this.thumbnail
                            },
                            query: {
                                id_author: this.selected_id
                            }
                        }

                        await this.$store.dispatch('authors/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {
                        let payload = {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            second_surname: this.second_surname,
                            specialty: this.speciality,
                            picture: this.thumbnail
                        }
                        
                        await this.$store.dispatch('authors/add', payload)
                        this.filterElements()
                        this.clearFields()
                        
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('authors/delete', { id_author: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },

            async activateElement() {
                await this.$store.dispatch('authors/delete', { id_author: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        computed: {
            ...mapGetters({
                author: 'authors/getAuthor',
                authors: 'authors/getAuthors',
                last_page: 'authors/getLastPage',
                total_pages: 'authors/getTotalPages'
            })
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'AUTORES')
                this.$store.commit('setIndex', 0)

                //list of authors
                await this.$store.dispatch('authors/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                this.authors_data = this.authors.map( author => {
                    return {
                        ...author,
                        actions: '-',
                        open: false
                    }
                })
                
                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>